export function fetchBrands(params) {
  return window.axios
    .get("/brands/find-all", {
      params: { ...params },
    })
    .then((response) => response.data);
}

export function fetchBrandById(id) {
  return window.axios
    .get(`/brands/find-by-id?id=${id}`)
    .then((response) => response.data);
}

export function insertBrand(data) {
  return window.axios
    .post("/brands/insert", data)
    .then((response) => response);
}

export function activateBrandById(id) {
  return window.axios
    .post(`/brands/activate/${id}`)
    .then((response) => response.data);
}

export function deactivateBrandById(id) {
  return window.axios
    .post(`/brands/deactivate/${id}`)
    .then((response) => response.data);
}
