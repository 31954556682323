<template>
  <v-carousel
    class="feedback-carousel"
    hide-delimiters
    height="auto"
    :show-arrows="attachments.length > 1 && !loading"
    @change="handleItemChanged"
  >
    <template v-slot:prev="{ on, attrs }">
      <v-btn icon color="transparent" v-bind="attrs" v-on="on" small>
        <v-icon color="white">mdi-chevron-left</v-icon>
      </v-btn>
    </template>

    <template v-slot:next="{ on, attrs }">
      <v-btn icon color="transparent" v-bind="attrs" v-on="on" small>
        <v-icon color="white">mdi-chevron-right</v-icon>
      </v-btn>
    </template>

    <d-loading v-if="loading"></d-loading>
    <template v-else>
      <v-carousel-item
        v-for="(attachment, index) in updatedAttachments"
        :key="index"
      >
        <video-player
          ref="myPlayer"
          class="carousel vjs-custom-skin"
          v-if="attachment.mimeType === 'video/mp4'"
          :options="{
            muted: true,
            language: 'en',
            playbackRates: [0.7, 1.0, 1.5, 2.0],
            sources: [
              {
                type: attachment.mimeType,
                src: attachment.data,
              },
            ],
          }"
        />
        <v-img
          v-else
          class="carousel image"
          :src="attachment.data"
          :lazy-src="`https://picsum.photos/10/6?image=${index * 5 + 10}`"
          contain
        ></v-img>
      </v-carousel-item>
    </template>
  </v-carousel>
</template>

<script>
import axios from "axios";

export default {
  props: {
    attachments: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      token: null,
      elementCountsInOneLine: 1,
      updatedAttachments: [],
    };
  },
  created() {
    this.loading = true;

    this.token = localStorage.getItem(process.env.VUE_APP_ACCESS_TOKEN_KEY);

    this.updatedAttachments = [...this.attachments];
    const promises = this.updatedAttachments.map((attachment) =>
      this.fetchAttachment(attachment.url)
    );

    Promise.all(promises)
      .then((values) => {
        for (let index = 0; index < values.length; index++) {
          this.updatedAttachments[index].data = values[index];
        }
      })
      .catch(() => {
        this.showFetchRequestErrorMessage();
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    handleItemChanged() {
      if (!this.$refs.myPlayer) return;

      const player = this.$refs.myPlayer[0].player;
      player.pause();
    },
    async fetchAttachment(url) {
      const response = await axios.get(url, {
        headers: {
          Authorization: "Bearer " + this.token,
        },
        responseType: "blob",
      });

      return URL.createObjectURL(response.data);
    },
  },
};
</script>
