export function fetchFeedbacks(params) {
  return window.axios
    .get("/feedbacks/find-all/not-approved", {
      params: { ...params },
    })
    .then((response) => response.data);
}

export function fetchFeedbackById(id) {
  return window.axios
    .get(`/feedbacks/find-by-id/${id}`)
    .then((response) => response.data);
}

export function fetchReportedFeedbacksByCount(params) {
  return window.axios
    .get("/feedbacks/complaints/find-all/by-count", {
      params: { ...params },
    })
    .then((response) => response.data);
}

export function fetchFeedbackComplaintsByFeedbackId(params) {
  return window.axios
    .get("/feedbacks/complaints/find-all", {
      params: { ...params },
    })
    .then((response) => response.data);
}

export function approveFeedback(id) {
  return window.axios
    .post(`/feedbacks/approve/${id}`)
    .then((response) => response.data);
}

export function declineFeedback(id) {
  return window.axios
    .post(`/feedbacks/decline/${id}`)
    .then((response) => response.data);
}
