export function fetchGroups(params) {
  return window.axios
    .get("/groups/find-all", {
      params: { ...params },
    })
    .then((response) => response.data);
}

export function fetchGroupById(id) {
  return window.axios
    .get(`/groups/find-by-id?id=${id}`)
    .then((response) => response.data);
}

export function blockGroupById(id) {
  return window.axios
    .get(`/groups/${id}/block`)
    .then((response) => response.data);
}
