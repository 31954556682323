<template>
  <div>
    <v-row>
      <v-col class="feedback-content" cols="12">
        <span class="feedback-content--description subtitle-2">{{
          fillEmptyData(description)
        }}</span>
        <v-row
          v-if="$vuetify.breakpoint.smAndUp"
          class="mt-4 feedback-content__attachments"
        >
          <template v-for="(attachment, index) in attachments">
            <v-col
              :key="index"
              v-if="index <= 2"
              v-ripple
              class="feedback-content--attachment px-0"
              cols="12"
              sm="6"
              md="6"
              :class="{
                'line-first-attachment':
                  index % 2 === 0 && $vuetify.breakpoint.smAndUp,
              }"
              @click="overlay = true"
            >
              <video-player
                v-if="attachment.mimeType === 'video/mp4'"
                class="vjs-custom-skin"
                :options="
                  videoPlayerOptions(attachment.mimeType, attachment.url)
                "
              />
              <v-img
                v-else
                :src="attachment.url"
                :lazy-src="`https://picsum.photos/10/6?image=${index * 5 + 10}`"
              />
            </v-col>
            <v-col
              :key="index"
              v-else-if="index === 3"
              class="feedback-content--attachment px-0"
              cols="12"
              sm="6"
              md="6"
              :class="{
                'line-first-attachment':
                  index % 2 === 0 && $vuetify.breakpoint.smAndUp,
              }"
              @click="overlay = true"
            >
              <span
                v-if="attachments.length > 4"
                class="cover-text text-h2 font-weight-bold"
              >
                +{{ attachments.length - 4 }}
              </span>
              <video-player
                v-if="attachment.mimeType === 'video/mp4'"
                class="vjs-custom-skin"
                :options="
                  videoPlayerOptions(attachment.mimeType, attachment.url)
                "
              />
              <v-img
                v-else
                :src="attachment.url"
                :lazy-src="`https://picsum.photos/10/6?image=${index * 5 + 10}`"
              />
            </v-col>
          </template>
        </v-row>
        <Carousel
          v-else
          class="mt-4"
          v-click-outside="handleClickOutside"
          :attachments="attachments"
        />
      </v-col>
    </v-row>

    <v-overlay :z-index="999" :value="overlay" class="overlay-attachment">
      <Carousel
        v-click-outside="handleClickOutside"
        :attachments="attachments"
      />
    </v-overlay>
  </div>
</template>

<script>
import Carousel from "./AttachmentsAsCarousel.vue";

export default {
  props: {
    description: {
      type: String,
    },
    attachments: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Carousel,
  },
  data() {
    return {
      overlay: false,
    };
  },
  computed: {
    videoPlayerOptions() {
      return (mimeType, url) => ({
        muted: true,
        language: "en",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: mimeType,
            src: url,
          },
        ],
      });
    },
  },
  methods: {
    handleClickOutside() {
      this.overlay = false;
    },
  },
};
</script>

<style lang="scss">
.feedback-content {
  &--description {
    color: var(--v-unknown-base);
  }

  &--attachment {
    position: relative;
    cursor: pointer;
    margin-bottom: 2px !important;

    .cover-text {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      text-align: center;
      padding-top: 25%;
      color: darkturquoise;
    }

    .cover-text::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: black;
      opacity: 0.3;
    }
  }

  &--attachment.line-first-attachment,
  [class*="col-6"] {
    padding-right: 2px !important;
  }

  &--attachment,
  &--attachment > .v-image,
  .vjs-custom-skin > .video-js {
    height: 215px;
  }
}
</style>
