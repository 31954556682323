export default {
  data() {
    return {
      loading: {
        table: false,
      },
      table: {
        items: [],
        totalCount: 0,
        page: 1,
        itemsPerPage: 15,
        options: {},
        isSearched: false,
        itemsPerPageOptions: [5, 10, 15, 50],
      },
    };
  },
  watch: {
    "loading.table": {
      handler(val) {
        if (val) this.$eventBus.$emit("searchStarted");
        else this.$eventBus.$emit("searchFinished");
      },
    },
  },
};
