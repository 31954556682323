<template>
  <d-page :title="$t('reported-users.page-heading')">
    <template slot="search">
      <Search @clear="handleCloseSearch" @search="handleSubmitSearch" />
    </template>
    <template slot="content">
      <List @viewItem="handleViewItem" @getTable="handleGetTable"></List>
    </template>
  </d-page>
</template>

<script>
import { mapGetters } from "vuex";
import List from "./List.vue";
import Search from "./Search.vue";

var CryptoJS = require("crypto-js");

export default {
  components: {
    List,
    Search,
  },
  data() {
    return {
      table: null,
    };
  },
  computed: {
    ...mapGetters({
      searchParams: "SearchParams/getSearchParams",
    }),
  },
  methods: {
    async handleCloseSearch() {
      this.$eventBus.$emit("fetchReportedUsersByCount", true);
    },
    handleSubmitSearch() {
      this.$eventBus.$emit("fetchReportedUsersByCount");
    },
    handleViewItem(item) {
      const cryptedFeedback = CryptoJS.AES.encrypt(
        JSON.stringify(item),
        process.env.VUE_APP_AES_SECRET_KEY
      ).toString();

      this.$router.push({
        name: "ReportedUserComplaints",
        query: { feedback: cryptedFeedback },
      });
    },
    handleGetTable(value) {
      this.table = value;
    },
  },
};
</script>
