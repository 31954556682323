export function fetchCategories(params) {
  return window.axios
    .get("/categories/find-all", {
      params: { ...params },
    })
    .then((response) => response.data);
}

export function fetchAllActiveCategories(params) {
  return window.axios
    .get("/categories/find-all-active", {
      params: { ...params },
    })
    .then((response) => response.data);
}

export function fetchCategoryById(id) {
  return window.axios
    .get(`/categories/find-by-id?id=${id}`)
    .then((response) => response.data);
}

export function insertCategory(data) {
  return window.axios
    .post("/categories/insert", data)
    .then((response) => response);
}

export function updateCategory(data) {
  return window.axios
    .put("/categories/update", data)
    .then((response) => response);
}
