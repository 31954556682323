<template>
  <v-row>
    <v-col cols="12" sm="12" md="3">
      <v-text-field
        :label="$t('brands.create.form.name')"
        v-model.trim="searchParams.name"
        outlined
        type="text"
        clearable
        hide-details
        dense
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="12" md="3" :class="marginSize">
      <v-select
        :items="parentCategories"
        v-model="searchParams.parentCategoryId"
        item-text="name"
        item-value="id"
        :label="$t('brands.create.form.parent-category')"
        outlined
        :loading="loading.parentCategory"
        :disabled="loading.parentCategory"
        clearable
        hide-details
        dense
      >
      </v-select>
    </v-col>
    <v-col cols="12" sm="12" md="3" :class="marginSize">
      <v-select
        :items="subCategories"
        v-model="searchParams.subCategoryId"
        item-text="name"
        item-value="id"
        :label="$t('brands.create.form.sub-category')"
        outlined
        :disabled="!searchParams.parentCategoryId || loading.subCategory"
        :loading="loading.subCategory"
        clearable
        hide-details
        dense
      >
      </v-select>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      loading: {
        parentCategory: false,
        subCategory: false,
      },
      parentCategories: [],
      subCategories: [],
    };
  },
  watch: {
    searchParams: {
      handler() {
        this.setSearchParams(this.searchParams);
      },
      immediate: true,
      deep: true,
    },
    "searchParams.parentCategoryId": {
      handler(value) {
        if (!value) {
          this.subCategoryId = null;
          this.subCategories = [];
          return;
        }

        this.fetchAllActiveSubCategoriesByParentId(value);
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      searchParams: "SearchParams/getSearchParams",
    }),
    marginSize() {
      return this.$vuetify.breakpoint.xs ? "mt-4" : "";
    },
  },
  methods: {
    ...mapActions({
      setSearchParams: "SearchParams/setSearchParams",
    }),
    async fetchAllActiveParentCategories() {
      this.loading.parentCategory = true;

      try {
        this.parentCategories = await window.API.fetchAllActiveCategories({
          limit: 2147483647,
        });
      } catch (error) {
        this.$eventBus.$emit("notification", {
          type: "error",
          message: this.$t("defaults.alert.could-not-fetch"),
        });
      } finally {
        this.loading.parentCategory = false;
      }
    },
    async fetchAllActiveSubCategoriesByParentId(parentId) {
      this.loading.subCategory = true;

      try {
        const params = {
          parentId,
          limit: 2147483647,
        };
        this.subCategories = await window.API.fetchAllActiveCategories(params);
      } catch (error) {
        this.$eventBus.$emit("notification", {
          type: "error",
          message: this.$t("defaults.alert.could-not-fetch"),
        });
      } finally {
        this.loading.subCategory = false;
      }
    },
  },
  async mounted() {
    await this.fetchAllActiveParentCategories();
  },
};
</script>
