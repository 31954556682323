import Vue from "vue";
import components from "./components/components";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import "@/assets/sass/main.scss";
import { i18n } from "./plugins/i18n";
import axios from "./plugins/axios";
import API from "./services";
import globalMixin from "./mixins/globalMixins";
import "video.js/dist/video-js.css";
import { videoPlayer } from "vue-video-player";

window.axios = axios;
window.API = API;

Vue.config.productionTip = false;

export const eventBus = new Vue();
Vue.prototype.$eventBus = eventBus;

components.forEach(({ name, component }) => {
  Vue.component(name, component);
});

Vue.component("v-granted", require("@/directives/authorization.js"));
Vue.component("v-click-outside", require("@/directives/clickOutside.js"));

Vue.component("video-player", videoPlayer);

Vue.mixin(globalMixin);
Vue.use(require("vue-moment"));

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
