<template>
  <v-row>
    <v-col cols="12">
      <v-text-field
        class="category-search-input"
        :label="$t('categories.create-or-update.form.name')"
        v-model.trim="searchParams.criteria"
        filled
        rounded
        type="text"
        append-icon="mdi-magnify"
        clearable
        dense
        hide-details="auto"
        @click:clear="handleClear"
        @click:append="handleSearch"
        @keydown.enter="handleSearch"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      oldSearchText: null,
    };
  },
  watch: {
    searchParams: {
      handler() {
        this.setSearchParams(this.searchParams);
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      searchParams: "SearchParams/getSearchParams",
    }),
  },
  methods: {
    ...mapActions({
      setSearchParams: "SearchParams/setSearchParams",
    }),
    handleClear() {
      this.oldSearchText = null;
      this.$emit("clear");
    },
    handleSearch() {
      if (this.oldSearchText != this.searchParams.criteria) {
        this.oldSearchText = this.searchParams.criteria;

        this.oldSearchText && this.$emit("search");
        !this.oldSearchText && this.$emit("clear");
      }
    },
  },
};
</script>
