var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-carousel',{staticClass:"feedback-carousel",attrs:{"hide-delimiters":"","height":"auto","show-arrows":_vm.attachments.length > 1 && !_vm.loading},on:{"change":_vm.handleItemChanged},scopedSlots:_vm._u([{key:"prev",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"transparent","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-chevron-left")])],1)]}},{key:"next",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"transparent","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-chevron-right")])],1)]}}])},[(_vm.loading)?_c('d-loading'):_vm._l((_vm.updatedAttachments),function(attachment,index){return _c('v-carousel-item',{key:index},[(attachment.mimeType === 'video/mp4')?_c('video-player',{ref:"myPlayer",refInFor:true,staticClass:"carousel vjs-custom-skin",attrs:{"options":{
            muted: true,
            language: 'en',
            playbackRates: [0.7, 1.0, 1.5, 2.0],
            sources: [
              {
                type: attachment.mimeType,
                src: attachment.data,
              },
            ],
          }}}):_c('v-img',{staticClass:"carousel image",attrs:{"src":attachment.data,"lazy-src":`https://picsum.photos/10/6?image=${index * 5 + 10}`,"contain":""}})],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }