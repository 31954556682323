<template>
  <d-page
    isCustomized
    :title="$t('brands.page-heading')"
    :searchPanelCol="12"
    :actionPanelCol="12"
  >
    <template slot="actions">
      <v-btn
        :block="$vuetify.breakpoint.xs"
        depressed
        large
        :disable="loading"
        :loading="loading"
        @click="handleCloseSearch"
      >
        <span class="subtitle-2 text-none">{{
          $t("brands.button.clear")
        }}</span>
      </v-btn>
      <v-btn
        :block="$vuetify.breakpoint.xs"
        :class="buttonMarginSize"
        depressed
        large
        :loading="loading"
        @click="handleSubmitSearch"
      >
        <v-icon left> mdi-magnify </v-icon>
        <span class="subtitle-2 text-none">{{
          $t("brands.button.search-toggle")
        }}</span>
      </v-btn>
      <v-btn
        :block="$vuetify.breakpoint.xs"
        :class="buttonMarginSize"
        depressed
        large
        :loading="loading"
        color="primary"
        @click="handleAddBrand"
      >
        <span class="subtitle-2 text-none">{{
          $t("brands.button.add-brand")
        }}</span>
      </v-btn>
    </template>
    <template slot="search">
      <Search />
    </template>
    <template slot="content">
      <List
        @viewItem="handleOpenViewModal"
        @toggleItemActivity="handleOpenToggleItemActivityModal"
        @getTable="handleGetTable"
      ></List>
      <AddCategory
        v-if="openAddCategoryModal"
        :modal="openAddCategoryModal"
        @closeModal="openAddCategoryModal = false"
      ></AddCategory>
      <Info
        v-if="openViewModal"
        :modal="openViewModal"
        :item="selectedItem"
        @closeModal="handleCloseModal"
      ></Info>
      <Activity
        v-if="openToggleItemActivityModel"
        :modal="openToggleItemActivityModel"
        :item="selectedItem"
        @closeModal="handleCloseModal"
        @submitModal="handleSubmitToggleItemActivityModal"
      ></Activity>
    </template>
  </d-page>
</template>

<script>
import { mapGetters } from "vuex";
import List from "./List.vue";
import Search from "./Search.vue";

export default {
  components: {
    List,
    Search,
    AddCategory: () => import("./AddCategory.vue"),
    Info: () => import("./View.vue"),
    Activity: () => import("./Activity.vue"),
  },
  data() {
    return {
      openAddCategoryModal: false,
      openViewModal: false,
      openToggleItemActivityModel: false,
      selectedItem: null,
      table: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      searchParams: "SearchParams/getSearchParams",
    }),
    buttonMarginSize() {
      return this.$vuetify.breakpoint.xs ? "mt-3" : "ml-4";
    },
  },
  methods: {
    handleAddBrand() {
      this.$router.push("/add-brand");
    },
    async handleCloseSearch() {
      this.$eventBus.$emit("fetchBrands", true);
    },
    handleSubmitSearch() {
      this.$eventBus.$emit("fetchBrands");
    },
    handleOpenViewModal(item = null) {
      this.openViewModal = true;
      this.selectedItem = item;
    },
    handleCloseModal() {
      this.openViewModal = false;
      this.openToggleItemActivityModel = false;
    },
    handleOpenToggleItemActivityModal(item = null) {
      this.openToggleItemActivityModel = true;
      this.selectedItem = item;
    },
    handleSubmitToggleItemActivityModal() {
      this.handleCloseModal();
      this.$eventBus.$emit("fetchBrands", true);
    },
    handleGetTable(value) {
      this.table = value;
    },
  },
};
</script>
