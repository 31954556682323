import * as userServices from "./user";
import * as groupServices from "./group";
import * as feedbackServices from "./feedback";
import * as brandServices from "./brand";
import * as categoryServices from "./category";
import * as tokenServices from "./token";

export default {
  ...userServices,
  ...groupServices,
  ...feedbackServices,
  ...brandServices,
  ...categoryServices,
  ...tokenServices,
};
