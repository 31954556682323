<template>
  <section class="d-page">
    <v-row class="d-page__main">
      <v-col
        cols="12"
        sm="12"
        :md="searchPanelCol"
        class="d-page__search"
        :class="{ 'right-empty': $vuetify.breakpoint.mdAndUp && !isCustomized }"
        align-self="center"
      >
        <slot name="search"></slot>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        :md="actionPanelCol"
        class="d-page__actions"
        :class="{ 'left-empty': $vuetify.breakpoint.mdAndUp && !isCustomized }"
        align-self="center"
      >
        <slot name="actions"></slot>
      </v-col>
      <v-col cols="12" md="12" sm="12" class="d-page__content">
        <slot name="content"></slot>
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {
  props: {
    isCustomized: {
      type: Boolean,
      default: () => false,
    },
    searchPanelCol: {
      type: Number,
      default: () => 4,
    },
    actionPanelCol: {
      type: Number,
      default: () => 8,
    },
  },
  methods: {
    closeSearch() {
      this.$emit("closeSearch");
    },
    submitSearch() {
      const isFormValid = this.$refs.form.validate();
      if (isFormValid) {
        this.$emit("submitSearch");
      }
    },
    searchStarted() {
      this.loading = true;
    },
    searchFinished() {
      this.loading = false;
    },
  },
  created() {
    this.$eventBus.$on("searchStarted", this.searchStarted);
    this.$eventBus.$on("searchFinished", this.searchFinished);
  },
  beforeDestroy() {
    this.$eventBus.$off("searchStarted", this.searchStarted);
    this.$eventBus.$off("searchFinished", this.searchFinished);
  },
};
</script>

<style lang="scss" scoped>
.d-page {
  &__main {
    padding: 16px;
    margin: 0;
  }

  &__search,
  &__actions {
    margin-bottom: 16px;
  }

  &__search,
  &__actions,
  &__content {
    padding: 0 !important;
  }

  &__search.right-empty {
    padding-right: 8px !important;
  }

  &__actions.left-empty {
    padding-left: 148px !important;
  }
}
</style>
