export function logIn(username, password) {
  return window.axios
    .post("/users/sign-in", {
      username,
      password,
    })
    .then((response) => response.data);
}

export function fetchUsers(params) {
  return window.axios
    .get("/users/find-all", {
      params: { ...params },
    })
    .then((response) => response.data);
}

export function fetchReportedUsersByCount(params) {
  return window.axios
    .get("/users/complaints/find-all/by-count", {
      params: { ...params },
    })
    .then((response) => response.data);
}

export function fetchUserComplaintsByUserId(params) {
  return window.axios
    .get("/users/complaints/find-all", {
      params: { ...params },
    })
    .then((response) => response.data);
}

export function exportUsers(params) {
  return window.axios
    .get("/users/export", {
      params: { ...params },
    })
    .then((response) => response.data);
}

export function fetchUserById(id) {
  return window.axios
    .get(`/users/find-by-id?id=${id}`)
    .then((response) => response.data);
}

export function blockUserById(id) {
  return window.axios
    .post(`/users/block/${id}`)
    .then((response) => response.data);
}
