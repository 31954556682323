<template>
  <v-app-bar outlined app flat color="#F4F4F8" height="56">
    <v-row align="center">
      <v-col cols="4">
        <v-app-bar-nav-icon @click="setNavigationDrawer()"></v-app-bar-nav-icon>
      </v-col>
      <v-col cols="8">
        <div class="d-flex align-center justify-end">
          <v-menu
            offset-y
            content-class="elevation-0 mt-1 rounded"
            v-if="loggedInUser"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="gray"
                text
                rounded
                v-bind="attrs"
                v-on="on"
                class="pa-0"
              >
                <div class="d-flex align-center">
                  <v-avatar color="primary" size="32">
                    <d-auth-image
                      v-if="hasProfilePicture"
                      :src="loggedInUser.image"
                      @error="handleProfilePictureError"
                    ></d-auth-image>
                    <span v-else class="body-1 white--text">{{
                      loggedInUserAvatar
                    }}</span>
                  </v-avatar>
                  <span
                    class="subtitle-2 text-none pl-2 pr-3 gray--text text--darken-3"
                    >{{ loggedInUserFullName }}
                  </span>
                </div>
              </v-btn>
            </template>
          </v-menu>
        </div>
      </v-col>
    </v-row>

    <v-toolbar-title></v-toolbar-title>
  </v-app-bar>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      profilePictureError: false,
    };
  },
  computed: {
    ...mapGetters({
      loggedInUser: "Auth/getLoggedInUser",
    }),
    loggedInUserAvatar() {
      return (
        this.loggedInUser.firstName.charAt(0).toUpperCase() +
        this.loggedInUser.lastName.charAt(0).toUpperCase()
      );
    },
    loggedInUserFullName() {
      return `${this.loggedInUser.firstName} ${this.loggedInUser.lastName}`;
    },
    hasProfilePicture() {
      return !!this.loggedInUser.image;
    },
  },
  methods: {
    ...mapActions({
      setNavigationDrawer: "Common/setNavigationDrawer",
    }),
    handleProfilePictureError(error) {
      this.profilePictureError = error;
    },
  },
};
</script>

<style lang="scss">
.theme--light.v-app-bar.v-toolbar.v-sheet {
  border-bottom: 1px solid var(--v-gray-lighten2) !important;
}
</style>
