<template>
  <d-page :title="$t('feedbacks.page-heading')">
    <template slot="actions">
      <v-btn
        class="mr-2"
        depressed
        large
        outlined
        color="primary"
        :disabled="!isSearched"
        @click="handleSubmitSearch"
      >
        <v-icon left> mdi-filter </v-icon>
        <span class="subtitle-2 text-none">{{
          $t("feedbacks.button.search")
        }}</span>
      </v-btn>
    </template>
    <template slot="search">
      <Search @clear="handleCloseSearch" @search="handleSubmitSearch" />
    </template>
    <template slot="content">
      <List
        @approveOrDeclineItem="handleApproveOrDeclineItem"
        @getTable="handleGetTable"
      ></List>
    </template>
  </d-page>
</template>

<script>
import { mapGetters } from "vuex";
import List from "./List.vue";
import Search from "./Search.vue";

export default {
  components: {
    List,
    Search,
  },
  data() {
    return {
      selectedItem: null,
      table: null,
    };
  },
  computed: {
    ...mapGetters({
      searchParams: "SearchParams/getSearchParams",
    }),
    isSearched() {
      return (
        this.searchParams &&
        Object.keys(this.searchParams).some(
          (paramKey) => this.searchParams[paramKey]
        )
      );
    },
  },
  methods: {
    async handleCloseSearch() {
      this.$eventBus.$emit("fetchFeedbacks", true);
    },
    handleSubmitSearch() {
      this.$eventBus.$emit("fetchFeedbacks");
    },
    handleApproveOrDeclineItem() {
      this.$eventBus.$emit("fetchFeedbacks", true);
    },
    handleGetTable(value) {
      this.table = value;
    },
  },
};
</script>
