const accessToken = localStorage.getItem(process.env.VUE_APP_ACCESS_TOKEN_KEY);
const refreshToken = localStorage.getItem(
  process.env.VUE_APP_REFRESH_TOKEN_KEY
);
const loggedInUser = JSON.parse(
  localStorage.getItem(process.env.VUE_APP_USER_DATA_KEY)
);

const userRole = localStorage.getItem(process.env.VUE_APP_USER_ROLE_KEY);

const initialData = {
  accessToken,
  refreshToken,
  userRole,
  loggedInUser,
};

let state = {
  ...initialData,
};

const getters = {
  isLoggedIn: (state) => !!state.accessToken,
  getAccessToken: (state) => state.accessToken,
  getRefreshToken: (state) => state.refreshToken,
  getUserRole: (state) => state.userRole,
  getLoggedInUser: (state) => state.loggedInUser,
};

const actions = {
  setAccessToken({ commit }, accessToken) {
    commit("SET_ACCESS_TOKEN", accessToken);
  },
  setRefreshToken({ commit }, refreshToken) {
    commit("SET_REFRESH_TOKEN", refreshToken);
  },
  updateTokens({ commit }, { refreshToken, accessToken }) {
    commit("SET_ACCESS_TOKEN", accessToken);
    commit("SET_REFRESH_TOKEN", refreshToken);
  },
  setUserRole({ commit }, role) {
    commit("SET_USER_ROLE", role);
  },
  setLoggedInUser({ commit }, loggedInUser) {
    commit("SET_LOGGED_IN_USER", loggedInUser);
  },
  loggedOutUser({ commit }) {
    commit("LOG_OUT_USER");
  },
};

const mutations = {
  SET_ACCESS_TOKEN(state, token) {
    state.accessToken = token;
    localStorage.setItem(process.env.VUE_APP_ACCESS_TOKEN_KEY, token);
  },
  SET_REFRESH_TOKEN(state, token) {
    state.refreshToken = token;
    localStorage.setItem(process.env.VUE_APP_REFRESH_TOKEN_KEY, token);
  },
  SET_USER_ROLE(state, role) {
    state.userRole = role;
    localStorage.setItem(process.env.VUE_APP_USER_ROLE_KEY, role);
  },
  SET_LOGGED_IN_USER(state, loggedInUser) {
    state.loggedInUser = loggedInUser;
    localStorage.setItem(
      process.env.VUE_APP_USER_DATA_KEY,
      JSON.stringify(loggedInUser)
    );
  },
  LOG_OUT_USER() {
    localStorage.clear();
    state = { ...initialData };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
