import Vue from "vue";
import authorizationUtils from "@/utils/authorization.js";

Vue.directive("granted", {
  inserted: function (el, binding, vnode) {
    if (!authorizationUtils.isGranted(binding.value)) {
      vnode.elm.parentElement.removeChild(vnode.elm);
    }
  },
});
