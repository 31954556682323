var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',[_c('v-col',{staticClass:"feedback-content",attrs:{"cols":"12"}},[_c('span',{staticClass:"feedback-content--description subtitle-2"},[_vm._v(_vm._s(_vm.fillEmptyData(_vm.description)))]),(_vm.$vuetify.breakpoint.smAndUp)?_c('v-row',{staticClass:"mt-4 feedback-content__attachments"},[_vm._l((_vm.attachments),function(attachment,index){return [(index <= 2)?_c('v-col',{directives:[{name:"ripple",rawName:"v-ripple"}],key:index,staticClass:"feedback-content--attachment px-0",class:{
                'line-first-attachment':
                  index % 2 === 0 && _vm.$vuetify.breakpoint.smAndUp,
              },attrs:{"cols":"12","sm":"6","md":"6"},on:{"click":function($event){_vm.overlay = true}}},[(attachment.mimeType === 'video/mp4')?_c('video-player',{staticClass:"vjs-custom-skin",attrs:{"options":_vm.videoPlayerOptions(attachment.mimeType, attachment.url)}}):_c('v-img',{attrs:{"src":attachment.url,"lazy-src":`https://picsum.photos/10/6?image=${index * 5 + 10}`}})],1):(index === 3)?_c('v-col',{key:index,staticClass:"feedback-content--attachment px-0",class:{
                'line-first-attachment':
                  index % 2 === 0 && _vm.$vuetify.breakpoint.smAndUp,
              },attrs:{"cols":"12","sm":"6","md":"6"},on:{"click":function($event){_vm.overlay = true}}},[(_vm.attachments.length > 4)?_c('span',{staticClass:"cover-text text-h2 font-weight-bold"},[_vm._v(" +"+_vm._s(_vm.attachments.length - 4)+" ")]):_vm._e(),(attachment.mimeType === 'video/mp4')?_c('video-player',{staticClass:"vjs-custom-skin",attrs:{"options":_vm.videoPlayerOptions(attachment.mimeType, attachment.url)}}):_c('v-img',{attrs:{"src":attachment.url,"lazy-src":`https://picsum.photos/10/6?image=${index * 5 + 10}`}})],1):_vm._e()]})],2):_c('Carousel',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.handleClickOutside),expression:"handleClickOutside"}],staticClass:"mt-4",attrs:{"attachments":_vm.attachments}})],1)],1),_c('v-overlay',{staticClass:"overlay-attachment",attrs:{"z-index":999,"value":_vm.overlay}},[_c('Carousel',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.handleClickOutside),expression:"handleClickOutside"}],attrs:{"attachments":_vm.attachments}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }