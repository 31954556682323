<template>
  <v-card id="add-brand" elevation="0" class="rounded-0">
    <v-card-title class="border-bottom">
      {{ $t("brands.create.title") }}
    </v-card-title>
    <v-card-text>
      <v-form
        ref="form"
        v-model="isFormValid"
        lazy-validation
        @submit.prevent="handleSubmitModal"
      >
        <v-row>
          <v-col cols="12" class="mb-10">
            <v-text-field
              :label="$t('brands.create.form.name')"
              hide-details="auto"
              outlined
              type="text"
              v-model.trim="form.name"
              :rules="rules.name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="mb-10">
            <v-select
              :items="parentCategories"
              v-model="form.parentCategoryId"
              item-text="name"
              item-value="id"
              :label="$t('brands.create.form.parent-category')"
              outlined
              hide-details="auto"
              :rules="rules.parentCategory"
              :loading="loading.parentCategory"
              :disabled="loading.parentCategory"
              clearable
            >
            </v-select>
          </v-col>
          <v-col cols="12" class="mb-3">
            <v-select
              :items="subCategories"
              v-model="form.subCategoryId"
              item-text="name"
              item-value="id"
              :label="$t('brands.create.form.sub-category')"
              outlined
              hide-details
              :disabled="!form.parentCategoryId || loading.subCategory"
              :loading="loading.subCategory"
              clearable
            >
            </v-select>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="d-flex justify-end">
      <v-btn
        class="text-none body-2 font-weight-medium mr-3"
        depressed
        outlined
        color="primary"
        @click="handleCloseModal"
      >
        {{ $t("brands.create.actions.cancel") }}
      </v-btn>
      <v-btn
        type="submit"
        color="primary"
        class="text-none body-2 font-weight-medium"
        depressed
        :loading="isSubmitting"
        :disabled="isSubmitting"
        @click="handleSubmitModal"
      >
        {{ $t("brands.create.actions.save") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import BrandErrorTypes from "@/shared/enums/BrandErrorTypes";

export default {
  initialForm: {
    name: null,
    parentCategoryId: null,
    subCategoryId: null,
  },
  data() {
    return {
      BrandErrorTypes,
      form: { ...this.initialForm },
      loading: {
        parentCategory: false,
        subCategory: false,
      },
      isFormValid: false,
      isSubmitting: false,
      rules: {
        name: [(v) => !!v || this.$t("defaults.rule.required")],
        parentCategory: [(v) => !!v || this.$t("defaults.rule.required")],
      },
      parentCategories: [],
      subCategories: [],
    };
  },
  watch: {
    "form.parentCategoryId": {
      handler(value) {
        if (!value) {
          this.subCategoryId = null;
          this.subCategories = [];
          return;
        }

        this.fetchAllActiveSubCategoriesByParentId(value);
      },
      immediate: true,
    },
  },
  methods: {
    async fetchAllActiveParentCategories() {
      this.loading.parentCategory = true;

      try {
        this.parentCategories = await window.API.fetchAllActiveCategories({
          limit: 2147483647,
        });
      } catch (error) {
        this.$eventBus.$emit("notification", {
          type: "error",
          message: this.$t("defaults.alert.could-not-fetch"),
        });
      } finally {
        this.loading.parentCategory = false;
      }
    },
    async fetchAllActiveSubCategoriesByParentId(parentId) {
      this.loading.subCategory = true;

      try {
        const params = {
          parentId,
          limit: 2147483647,
        };
        this.subCategories = await window.API.fetchAllActiveCategories(params);
      } catch (error) {
        this.$eventBus.$emit("notification", {
          type: "error",
          message: this.$t("defaults.alert.could-not-fetch"),
        });
      } finally {
        this.loading.subCategory = false;
      }
    },
    handleCloseModal() {
      this.$refs.form.reset();
      this.editMode = false;
      this.form = { ...this.initialForm };
      this.$router.replace("/brands");
    },
    async handleSubmitModal() {
      if (this.$refs.form.validate() && this.isFormValid) this.createItem();
    },
    createItem() {
      this.isSubmitting = true;

      const formData = {
        name: this.form.name,
        categoryId: this.form.subCategoryId
          ? this.form.subCategoryId
          : this.form.parentCategoryId,
      };

      window.API.insertBrand(formData)
        .then(() => {
          this.$eventBus.$emit("notification", {
            type: "success",
            message: this.$t("brands.create.alert.created", {
              name: formData.name,
            }),
          });
          this.handleCloseModal();
        })
        .catch((error) => {
          let message = this.$t("brands.create.alert.could-not-create");
          const response = error.response;

          if (
            response?.data?.apierror?.message ===
            BrandErrorTypes.BRAND_DUPLICATED
          ) {
            message = this.$t("brands.create.alert.duplicate-item");
          }

          this.$eventBus.$emit("notification", {
            type: "error",
            message: message,
          });
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
  },
  async mounted() {
    await this.fetchAllActiveParentCategories();
  },
};
</script>

<style lang="scss" scoped>
#add-brand .v-card {
  &__title {
    padding-left: 40px;
    font: normal bold 22px/29px Roboto;
    color: var(--v-unknown-base) !important;
  }

  &__text {
    padding: 60px 40px !important;
  }

  &__actions {
    padding: 60px 40px !important;
  }
}
</style>
