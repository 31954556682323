<template>
  <d-page
    id="complaints"
    :title="$t('reported-feedbacks.complaints.page-heading')"
  >
    <template slot="content">
      <ComplaintHeader
        :data="selectedItem"
        :loading="loading.button"
        :showAttachments="handleShowAttachments"
      />
      <d-loading v-if="loading.items" />
      <template v-else>
        <ComplaintItem
          v-for="(item, index) in table.items"
          :key="index"
          :data="item"
        />
      </template>

      <v-overlay :z-index="999" :value="overlay" class="overlay-attachment">
        <Carousel
          v-click-outside="handleClickOutside"
          :attachments="feedbackAttachments"
        />
      </v-overlay>
    </template>
  </d-page>
</template>

<script>
var CryptoJS = require("crypto-js");

import scrolledTableMixins from "@/mixins/scrolledTableMixins";
import Carousel from "@/views/feedbacks/FeedbackItem/AttachmentsAsCarousel.vue";

export default {
  mixins: [scrolledTableMixins],
  data() {
    return {
      selectedItem: null,
      loading: {
        items: false,
        button: false,
      },
      overlay: false,
      table: {
        items: [],
        totalCount: 0,
        page: 1,
        itemsPerPage: 50,
      },
      feedbackAttachments: [],
    };
  },
  components: {
    ComplaintHeader: () => import("./ComplaintHeader.vue"),
    ComplaintItem: () => import("./ComplaintItem.vue"),
    Carousel,
  },
  methods: {
    fetchItems() {
      this.loading.items = true;

      window.API.fetchFeedbackComplaintsByFeedbackId({
        limit: this.table.itemsPerPage,
        offset: (this.table.page - 1) * this.table.itemsPerPage,
        feedbackId: this.selectedItem.id,
      })
        .then((response) => {
          this.table.items = [...this.table.items, ...response];
          this.table.totalCount = this.table.items.length;
        })
        .catch(() => {
          this.showFetchRequestErrorMessage();
        })
        .finally(() => {
          this.loading.items = false;
        });
    },
    fetchFeedbackById(id) {
      this.loading.button = true;

      window.API.fetchFeedbackById(id)
        .then((response) => {
          const { attachments } = response;
          this.feedbackAttachments = attachments ? JSON.parse(attachments) : [];
        })
        .catch(() => {
          this.showFetchRequestErrorMessage();
        })
        .finally(() => {
          this.loading.button = false;
        });
    },
    handleClickOutside() {
      this.overlay = false;
      this.complaintAttachments = [];
    },
    handleShowAttachments() {
      this.overlay = true;
    },
  },
  created() {
    try {
      const { feedback } = this.$route.query;
      const bytes = CryptoJS.AES.decrypt(
        feedback,
        process.env.VUE_APP_AES_SECRET_KEY
      );
      this.selectedItem = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      this.fetchFeedbackById(this.selectedItem.id);
      this.fetchItems();
    } catch (_) {
      this.showFetchRequestErrorMessage();
    }
  },
};
</script>

<style lang="scss">
#complaints {
  .d-page__search,
  .d-page__actions {
    margin: 0 !important;
  }

  .complaint-header-card,
  .complaint-item-card {
    margin: 16px;
    border-radius: 8px;
    box-shadow: 0px 0px 6px #00000029 !important;
  }

  .complaint-header-card__title {
    font: normal bold 22px/29px Roboto;
    color: var(--v-unknown-base) !important;
  }

  .complaint-item-card__title.border-bttm {
    border-bottom: 1px solid #e9e9e9;
  }

  .complaint-item-card__title,
  .complaint-item-card__content {
    font: normal normal 14px/17px Roboto;
    color: var(--v-unknown-base) !important;
  }

  .complaint-item-card__content {
    padding: 10px;
  }
}
</style>
