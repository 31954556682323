export default {
  data() {
    return {
      scrolledToBottom: false,
    };
  },
  watch: {
    scrolledToBottom: {
      handler(value) {
        if (
          value &&
          this.table.items.length > 0 &&
          this.table.items.length === this.table.page * this.table.itemsPerPage
        ) {
          this.table.page++;
          this.fetchItems();
        }
      },
      immediate: true,
    },
  },
  methods: {
    scroll() {
      window.onscroll = () => {
        if (this.loading.table) return;

        this.scrolledToBottom =
          Math.round(
            Math.max(
              window.pageYOffset,
              document.documentElement.scrollTop,
              document.body.scrollTop
            ) + window.innerHeight
          ) === document.documentElement.offsetHeight;
      };
    },
  },
  created() {
    this.$emit("getTable", this.page);
  },
  mounted() {
    this.scroll();
  },
};
