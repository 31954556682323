<template>
  <d-page :title="$t('users.page-heading')">
    <template slot="actions">
      <v-btn class="mr-6" depressed outlined large color="primary">
        <v-icon left> mdi-filter </v-icon>
        <span class="subtitle-2 text-none">{{
          $t("users.button.search")
        }}</span>
      </v-btn>
      <v-btn
        class="ml-6"
        depressed
        outlined
        large
        color="primary"
        @click="exportUsers"
      >
        <v-icon left> mdi-export-variant </v-icon>
        <span class="subtitle-2 text-none">{{
          $t("users.button.export-data")
        }}</span>
      </v-btn>
    </template>
    <template slot="search">
      <Search @clear="handleCloseSearch" @search="handleSubmitSearch" />
    </template>
    <template slot="content">
      <List
        @viewItem="handleOpenViewModal"
        @blockItem="handleOpenBlockModal"
        @getTable="handleGetTable"
      ></List>
      <Info
        v-if="openViewModel"
        :modal="openViewModel"
        :item="selectedItem"
        @closeModal="handleCloseModal"
      ></Info>
      <Block
        v-if="openBlockModel"
        :modal="openBlockModel"
        :item="selectedItem"
        @closeModal="handleCloseModal"
        @submitModal="handleSubmitBlockModal"
      ></Block>
    </template>
  </d-page>
</template>

<script>
import { mapGetters } from "vuex";
import List from "./List.vue";
import Search from "./Search.vue";

export default {
  components: {
    List,
    Search,
    Info: () => import("./View.vue"),
    Block: () => import("./Block.vue"),
  },
  data() {
    return {
      openViewModel: false,
      openBlockModel: false,
      selectedItem: null,
      table: null,
    };
  },
  computed: {
    ...mapGetters({
      searchParams: "SearchParams/getSearchParams",
    }),
  },
  methods: {
    async handleCloseSearch() {
      this.$eventBus.$emit("fetchUsers", true);
    },
    handleSubmitSearch() {
      this.$eventBus.$emit("fetchUsers");
    },
    handleOpenViewModal(item = null) {
      this.openViewModel = true;
      this.selectedItem = item;
    },
    handleCloseModal() {
      this.openViewModel = false;
      this.openBlockModel = false;
    },
    handleOpenBlockModal(item = null) {
      this.openBlockModel = true;
      this.selectedItem = item;
    },
    handleSubmitBlockModal() {
      this.$eventBus.$emit("fetchUsers", true);
      this.openBlockModel = false;
    },
    handleGetTable(value) {
      this.table = value;
    },
    exportUsers() {
      window.API.exportUsers({
        ...this.searchParams,
      })
        .then(() => {
          this.$eventBus.$emit("notification", {
            type: "success",
            message: this.$t("users.export.alert.exported"),
          });
        })
        .catch(() => {
          this.$eventBus.$emit("notification", {
            type: "error",
            message: this.$t("users.export.alert.could-not-export"),
          });
        });
    },
  },
};
</script>
