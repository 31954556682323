import axios from "axios";

const axiosClient = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL + "/api",
  timeout: 10000,
});

export function refreshToken(refreshToken) {
  return axiosClient
    .post("/users/refresh-sign-in", {
      refreshToken,
    })
    .then((response) => response.data);
}
