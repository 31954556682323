import store from "@/store";
import router from "@/router";
import API from "@/services";

function redirectToLogin() {
  store.dispatch("Auth/logOut");
  router.push("/login");
}

export function getRefreshTokenPromise() {
  window.refreshTokenInProgress = true;

  const oldRefreshToken = store.getters["Auth/getRefreshToken"];
  return API.refreshToken(oldRefreshToken)
    .then((response) => {
      if (!response) {
        redirectToLogin();
      }
      const accessToken = response.accessToken;
      const refreshToken = response.refreshToken;
      store.dispatch("Auth/updateTokens", { accessToken, refreshToken });
      return response;
    })
    .catch(() => {
      redirectToLogin();
    })
    .finally(() => {
      window.refreshTokenInProgress = false;
    });
}
