<template>
  <div>
    <v-data-table
      v-if="table.items.length > 0 || loading.table"
      :page="table.page"
      :headers="headers"
      :items="table.items"
      :items-per-page="table.itemsPerPage"
      :loading="loading.table"
      :server-items-length="table.totalCount"
      :footer-props="{
        'items-per-page-options': table.itemsPerPageOptions,
      }"
      class="d-table"
      :options.sync="table.options"
      @update:options="optionsUpdated"
    >
      <template v-slot:[`item.image`]="{ item }">
        <v-avatar v-if="item.image" color="primary" size="46">
          <d-auth-image :src="item.image"></d-auth-image>
        </v-avatar>
        <v-icon v-else size="24" color="primary">mdi-image-off-outline</v-icon>
      </template>
      <template v-slot:[`item.createdDate`]="{ item }">
        <span>{{ item.createdDate | moment("DD.MM.YYYY") }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div class="d-flex align-center justify-start">
          <v-btn class="mr-4" text small @click="viewItem(item)">
            <v-icon color="primary" size="18"> mdi-information-outline </v-icon>
            <span class="ml-2 gray--text text--darken-3">{{
              $t("brands.list.table.actions.info")
            }}</span>
          </v-btn>
          <v-btn class="mx-4" text small @click="toggleItemActivity(item)">
            <v-icon color="primary" size="18">
              {{ activityInfo(item.isActive).icon }}
            </v-icon>
            <span class="ml-2 gray--text text--darken-3">{{
              activityInfo(item.isActive).text
            }}</span>
          </v-btn>
          <v-menu bottom offset-y content-class="elevation-0">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ml-4"
                color="gray darken-2"
                icon
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon size="18"> mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>
          </v-menu>
        </div>
      </template>
    </v-data-table>
    <d-page-empty
      :text="$t('brands.list.empty-page')"
      :hasCreateButton="false"
      v-if="!table.isSearched && table.totalCount == 0 && !loading.table"
    >
    </d-page-empty>
    <d-no-result
      v-if="table.isSearched && table.totalCount == 0 && !loading.table"
    ></d-no-result>
  </div>
</template>

<script>
import tableMixins from "@/mixins/tableMixins";
import paginatedTableMixins from "@/mixins/paginatedTableMixins";
import { mapActions, mapGetters } from "vuex";

export default {
  mixins: [tableMixins, paginatedTableMixins],
  data(vm) {
    return {
      headers: [
        {
          text: vm.$t("brands.list.table.headers.logo"),
          value: "image",
          sortable: false,
          align: "center",
          class: "subtitle-2 font-weight-bold",
        },
        {
          text: vm.$t("brands.list.table.headers.category-name"),
          value: "categoryName",
          sortable: false,
          align: "start",
          class: "subtitle-2 font-weight-bold",
        },
        {
          text: vm.$t("brands.list.table.headers.name"),
          value: "name",
          sortable: false,
          align: "start",
          class: "subtitle-2 font-weight-bold",
        },
        {
          text: vm.$t("brands.list.table.headers.createdDate"),
          value: "createdDate",
          sortable: false,
          align: "start",
          class: "subtitle-2 font-weight-bold",
        },
        {
          text: vm.$t("brands.list.table.headers.actions"),
          value: "actions",
          sortable: false,
          align: "start",
          class: "subtitle-2 font-weight-bold",
        },
      ],
      initialSearchParams: {},
    };
  },
  computed: {
    ...mapGetters({
      searchParams: "SearchParams/getSearchParams",
    }),
    activityInfo() {
      return (isActive) =>
        isActive
          ? {
              icon: "mdi-toggle-switch-off-outline",
              text: this.$t("brands.list.table.actions.deactivate"),
            }
          : {
              icon: "mdi-toggle-switch-outline",
              text: this.$t("brands.list.table.actions.activate"),
            };
    },
  },
  methods: {
    ...mapActions({
      setSearchParams: "SearchParams/setSearchParams",
    }),
    initSearchParams() {
      this.setSearchParams({ ...this.initialSearchParams });
    },
    handleFetchItems(isSearchCleared = false) {
      if (isSearchCleared) this.initSearchParams();

      this.table.options.page = 1;
      this.table.page = 1;
      this.fetchItems();
    },
    viewItem(item = null) {
      this.$emit("viewItem", item);
    },
    toggleItemActivity(item = null) {
      this.$emit("toggleItemActivity", item);
    },
    fetchItems() {
      this.loading.table = true;
      this.table.isSearched = !this.isObjectEmpty(this.searchParams);

      const searchParams = {
        name: this.searchParams.name,
        categoryId:
          this.searchParams.subCategoryId || this.searchParams.parentCategoryId,
      };

      window.API.fetchBrands({
        limit: this.table.itemsPerPage,
        offset: (this.table.page - 1) * this.table.itemsPerPage,
        ...searchParams,
      })
        .then((response) => {
          const totalCount = !response || !response.length ? 0 : 100_000; //response.totalCount

          this.table.items = response;
          this.table.totalCount = totalCount;

          if (
            totalCount <=
            (this.table.options.page - 1) * this.table.itemsPerPage
          ) {
            this.table.options.page = 1;
          }
        })
        .catch(() => {
          this.showFetchRequestErrorMessage();
        })
        .finally(() => {
          this.loading.table = false;
        });
    },
  },
  created() {
    this.$eventBus.$on("fetchBrands", this.handleFetchItems);
  },
  mounted() {
    this.initSearchParams();
    this.fetchItems();
  },
  beforeDestroy() {
    this.$eventBus.$off("fetchBrands", this.handleFetchItems);
  },
};
</script>
