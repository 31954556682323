<template>
  <v-row>
    <v-col cols="12" sm="6" align-self="center">
      <div class="feedback-author d-flex justify-start align-center">
        <v-avatar class="author__image mr-2" color="primary" size="54">
          <d-auth-image
            v-if="itemAuthor.image"
            :src="itemAuthor.image"
          ></d-auth-image>
          <span v-else class="body-1 white--text">{{
            loggedInUserAvatar(itemAuthor)
          }}</span>
        </v-avatar>
        <div class="feedback-author__info">
          <p class="feedback-author__info--fullname subtitle-2 mb-0">
            {{ itemAuthorFullname }}
          </p>
          <p class="feedback-author__info--date caption mb-0">
            Published:
            {{
              item.createdDate
                ? $moment(item.createdDate).format("MM.DD.YYYY")
                : fillEmptyData(null)
            }}
          </p>
          <p class="feedback-author__info--location caption mb-0">
            {{ fillEmptyData(item.location) }}
          </p>
        </div>
      </div>
    </v-col>
    <v-col cols="12" sm="6" align-self="center">
      <div class="feedback-actions d-flex justify-end align-center">
        <v-btn
          class="mr-4 rounded-lg subtitle-2 font-weight-bold"
          depressed
          color="primary"
          v-text="$t('feedbacks.list.item.actions.approve.text')"
          :loading="isSubmutting"
          :disabled="isSubmutting"
          @click="approveItem(item)"
        ></v-btn>
        <v-btn
          class="rounded-lg subtitle-2 font-weight-bold"
          depressed
          color="primary"
          outlined
          v-text="$t('feedbacks.list.item.actions.decline.text')"
          :loading="isSubmutting"
          :disabled="isSubmutting"
          @click="declineItem(item)"
        ></v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    approveItem: {
      type: Function,
      required: true,
    },
    declineItem: {
      type: Function,
      required: true,
    },
    isSubmutting: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    itemAuthor() {
      return JSON.parse(this.item.author);
    },
    itemAuthorFullname() {
      const { firstName, lastName } = this.itemAuthor;
      return !firstName && !lastName
        ? this.fillEmptyData(null)
        : `${firstName} ${lastName}`;
    },
  },
  methods: {
    loggedInUserAvatar(item) {
      return (
        item.firstName.charAt(0).toUpperCase() +
        item.lastName.charAt(0).toUpperCase()
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.feedback-author {
  &__info--date,
  &__info--location {
    color: var(--v-gray-lighten6);
  }
}
</style>
