import { Translations } from "../plugins/translations";

import LoginLayout from "@/layouts/LoginLayout.vue";
import AppLayout from "@/layouts/AppLayout.vue";
import Login from "@/views/login/Login.vue";
import Users from "@/views/users/index.vue";
import Groups from "@/views/groups/index.vue";
import Feedbacks from "@/views/feedbacks/index.vue";
import Brands from "@/views/brands/index.vue";
import AddBrand from "@/views/brands/Add.vue";
import Categories from "@/views/categories/index.vue";
import ReportedUsers from "@/views/reported-users/index.vue";
import ReportedUserComplaints from "@/views/reported-users/complaints/index.vue";
import ReportedFeedbacks from "@/views/reported-feedbacks/index.vue";
import ReportedFeedbackComplaints from "@/views/reported-feedbacks/complaints/index.vue";

const routes = [
  {
    path: "/",
    component: AppLayout,
    meta: {
      requiresAuth: true,
      isOnlyPublic: false,
      role: "*",
    },
    children: [
      {
        path: "/users",
        component: Users,
        name: "Users",
        meta: {
          requiresAuth: true,
          isOnlyPublic: false,
          role: "Administrator",
        },
      },
      {
        path: "/groups",
        component: Groups,
        name: "Groups",
        meta: {
          requiresAuth: true,
          isOnlyPublic: false,
          role: "Administrator",
        },
      },
      {
        path: "/feedbacks",
        component: Feedbacks,
        name: "Feedbacks",
        meta: {
          requiresAuth: true,
          isOnlyPublic: false,
          role: "Administrator",
        },
      },
      {
        path: "/brands",
        component: Brands,
        name: "Brands",
        meta: {
          requiresAuth: true,
          isOnlyPublic: false,
          role: "Administrator",
        },
      },
      {
        path: "/add-brand",
        component: AddBrand,
        name: "AddBrand",
        meta: {
          requiresAuth: true,
          isOnlyPublic: false,
          role: "Administrator",
        },
      },
      {
        path: "/categories",
        component: Categories,
        name: "Categories",
        meta: {
          requiresAuth: true,
          isOnlyPublic: false,
          role: "Administrator",
        },
      },
      {
        path: "/reported-feedbacks",
        component: ReportedFeedbacks,
        name: "ReportedFeedbacks",
        meta: {
          requiresAuth: true,
          isOnlyPublic: false,
          role: "Administrator",
        },
      },
      {
        path: "/reported-feedbacks-complaints",
        component: ReportedFeedbackComplaints,
        name: "ReportedFeedbackComplaints",
        meta: {
          requiresAuth: true,
          isOnlyPublic: false,
          role: "Administrator",
        },
      },
      {
        path: "/reported-users",
        component: ReportedUsers,
        name: "ReportedUsers",
        meta: {
          requiresAuth: true,
          isOnlyPublic: false,
          role: "Administrator",
        },
      },
      {
        path: "/reported-users-complaints",
        component: ReportedUserComplaints,
        name: "ReportedUserComplaints",
        meta: {
          requiresAuth: true,
          isOnlyPublic: false,
          role: "Administrator",
        },
      },
    ],
  },
  {
    path: "",
    component: LoginLayout,
    meta: {
      requiresAuth: false,
      isOnlyPublic: true,
      role: "*",
    },
    beforeEnter: Translations.routeMiddleware,
    children: [
      {
        path: "/login",
        name: "Login",
        component: Login,
        meta: {
          requiresAuth: false,
          isOnlyPublic: true,
          role: "*",
        },
      },
    ],
  },
];

export default routes;
