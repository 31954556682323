<template>
  <v-navigation-drawer
    v-model="navigationDrawer"
    app
    color="#F4F4F8"
    width="320"
  >
    <div class="menu my-12 mr-2 d-flex flex-column justify-space-between">
      <v-list shaped dense>
        <v-list-item-group v-model="selectedItem" color="primary">
          <template v-for="(item, outInd) in items">
            <v-list-group
              :value="checkSubmenuRouteChange(item)"
              :key="outInd"
              v-if="item.children && item.children.length > 0"
              v-granted="item.role"
              class="py-x-2 mx-4"
            >
              <template v-slot:activator>
                <v-list-item-icon class="mr-4">
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <span class="body-1" v-text="item.text"></span>
                  </v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                class="pl-10"
                v-for="(subitem, inInd) in item.children"
                :key="inInd"
                link
                :to="subitem.link"
                v-granted="subitem.role"
                @click="selectedItem = outInd + inInd + 1"
              >
                <v-list-item-icon
                  v-if="subitemIconVisibility(subitem, outInd, inInd)"
                  class="mr-4"
                >
                  <v-icon v-text="subitem.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  <span class="body-1" v-text="subitem.text"></span>
                </v-list-item-title>
              </v-list-item>
            </v-list-group>
            <v-list-item
              v-else
              :key="outInd"
              class="py-x-2 mx-4"
              :to="item.link"
              v-granted="item.role"
            >
              <v-list-item-icon class="mr-4">
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  <span class="body-1" v-text="item.text"></span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>

      <v-btn plain class="logout-btn ml-4" @click="handleLoggedOut">
        <v-icon class="mr-8">mdi-logout</v-icon>
        <span>{{ $t("menu.logout") }}</span>
      </v-btn>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data: (vm) => ({
    selectedItem: 1,
    items: [
      {
        link: "/users",
        text: vm.$t("menu.user-control"),
        icon: "mdi-account",
        role: "Administrator",
      },
      {
        link: "/groups",
        text: vm.$t("menu.group-control"),
        icon: "mdi-account-group",
        role: "Administrator",
      },
      {
        link: "/feedbacks",
        text: vm.$t("menu.feedback-control"),
        icon: "mdi-gamepad-circle-left",
        role: "Administrator",
      },
      {
        link: "/brands",
        text: vm.$t("menu.brand"),
        icon: "mdi-plus-box-multiple",
        role: "Administrator",
      },
      {
        link: "/categories",
        text: vm.$t("menu.category"),
        icon: "mdi-shape-plus",
        role: "Administrator",
      },
      {
        text: vm.$t("menu.report-management"),
        icon: "mdi-alert-octagon",
        role: "Administrator",
        children: [
          {
            link: "/reported-feedbacks",
            text: vm.$t("menu.reported-feedback"),
            icon: "mdi-circle-medium",
            role: "Administrator",
            checkSelecting: true,
          },
          {
            link: "/reported-users",
            text: vm.$t("menu.reported-user"),
            icon: "mdi-circle-medium",
            role: "Administrator",
            checkSelecting: true,
          },
        ],
      },
    ],
  }),
  computed: {
    navigationDrawer: {
      get: function () {
        return this.$store.getters["Common/getNavigationDrawer"];
      },
      set: function (val) {
        if (val != this.$store.getters["Common/getNavigationDrawer"]) {
          this.setNavigationDrawer();
        }
      },
    },
    subitemIconVisibility() {
      return (subitem, outIndex, inIndex) => {
        return (
          subitem.icon &&
          (!subitem.checkSelecting ||
            (subitem.checkSelecting &&
              this.selectedItem === outIndex + inIndex + 1))
        );
      };
    },
  },
  watch: {
    "$route.path": {
      handler() {
        this.checkSubmenuRouteChange();
      },
    },
  },
  methods: {
    ...mapActions({
      setNavigationDrawer: "Common/setNavigationDrawer",
      loggedOutUser: "Auth/loggedOutUser",
    }),
    checkSubmenuRouteChange(item) {
      if (!item) return;

      return item.children.some((i) => i.link === this.$route.path);
    },
    handleLoggedOut() {
      this.loggedOutUser();
      window.location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.menu {
  height: calc(100% - 96px);

  .logout-btn {
    justify-content: start;
  }
}
</style>
