<template>
  <d-page :title="$t('categories.page-heading')">
    <template slot="actions">
      <v-btn
        depressed
        large
        :loading="loading"
        color="primary"
        @click="handleOpenAddOrUpdateModal(null)"
      >
        <span class="subtitle-2 text-none">{{
          $t("categories.button.add-category")
        }}</span>
      </v-btn>
    </template>
    <template slot="search">
      <Search @clear="handleCloseSearch" @search="handleSubmitSearch" />
    </template>
    <template slot="content">
      <List
        @viewItem="handleOpenViewModal"
        @updateItem="handleOpenAddOrUpdateModal"
        @getTable="handleGetTable"
      ></List>
      <AddOrUpdate
        v-if="openAddOrUpdateModal"
        :modal="openAddOrUpdateModal"
        :item="selectedItem"
        @closeModal="handleCloseModal"
        @submitModal="handleSubmitAddOrUpdateModal"
      ></AddOrUpdate>
      <Info
        v-if="openViewModal"
        :modal="openViewModal"
        :item="selectedItem"
        @closeModal="handleCloseModal"
      ></Info>
    </template>
  </d-page>
</template>

<script>
import { mapGetters } from "vuex";
import List from "./List.vue";
import Search from "./Search.vue";

export default {
  components: {
    List,
    Search,
    AddOrUpdate: () => import("./AddOrUpdate.vue"),
    Info: () => import("./View.vue"),
  },
  data() {
    return {
      openAddOrUpdateModal: false,
      openViewModal: false,
      selectedItem: null,
      table: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      searchParams: "SearchParams/getSearchParams",
    }),
  },
  methods: {
    async handleCloseSearch() {
      this.$eventBus.$emit("fetchCategories", true);
    },
    handleSubmitSearch() {
      this.$eventBus.$emit("fetchCategories");
    },
    handleOpenAddOrUpdateModal(item = null) {
      this.openAddOrUpdateModal = true;
      this.selectedItem = item;
    },
    handleSubmitAddOrUpdateModal() {
      this.handleCloseModal();
      this.handleSubmitSearch();
    },
    handleOpenViewModal(item = null) {
      this.openViewModal = true;
      this.selectedItem = item;
    },
    handleCloseModal() {
      this.openViewModal = false;
      this.openAddOrUpdateModal = false;

      if (this.selectedItem) {
        this.selectedItem = null;
      }
    },
    handleGetTable(value) {
      this.table = value;
    },
  },
};
</script>
