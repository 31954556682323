export default {
  methods: {
    optionsUpdated(value) {
      const { page, itemsPerPage } = value;
      if (page > 0 || itemsPerPage > 0) {
        this.handleOptionsChanged({
          page: page,
          itemsPerPage: itemsPerPage,
        });
        this.$emit("getTable", this.table);
      }
    },
    handleOptionsChanged(item) {
      if (
        this.table.page != item.page ||
        this.table.itemsPerPage != item.itemsPerPage
      ) {
        this.table.page = item.page;
        this.table.itemsPerPage = item.itemsPerPage;
        this.fetchItems();
      }
    },
  },
};
