<template>
  <div>
    <v-data-table
      :page="table.page"
      :headers="headers"
      :items="table.items"
      :items-per-page="table.itemsPerPage"
      :loading="loading.table"
      :server-items-length="table.totalCount"
      :footer-props="{
        'items-per-page-options': table.itemsPerPageOptions,
      }"
      hide-default-footer
      v-if="table.items.length > 0 || loading.table"
      @click:row="handleClickedRow"
    >
      <template v-slot:[`item.user`]="{ item }">
        <span>{{ getUserFullname(item.user) }}</span>
      </template>
    </v-data-table>
    <d-page-empty
      :text="$t('reported-users.list.empty-page')"
      :hasCreateButton="false"
      v-if="!table.isSearched && table.totalCount == 0 && !loading.table"
    >
    </d-page-empty>
    <d-no-result
      v-if="table.isSearched && table.totalCount == 0 && !loading.table"
    ></d-no-result>
  </div>
</template>

<script>
import tableMixins from "@/mixins/tableMixins";
import scrolledTableMixins from "@/mixins/scrolledTableMixins";
import { mapActions, mapGetters } from "vuex";

export default {
  mixins: [tableMixins, scrolledTableMixins],
  data(vm) {
    return {
      headers: [
        {
          text: vm.$t("reported-users.list.table.headers.user"),
          value: "user",
          sortable: false,
          align: "start",
          class: "subtitle-2 font-weight-bold",
        },
        {
          text: vm.$t("reported-users.list.table.headers.count"),
          value: "count",
          sortable: false,
          align: "start",
          class: "subtitle-2 font-weight-bold",
        },
        {
          text: vm.$t("reported-users.list.table.headers.category"),
          value: "category",
          sortable: false,
          align: "start",
          class: "subtitle-2 font-weight-bold",
        },
      ],
      table: {
        itemsPerPage: 50,
      },
      initialSearchParams: {},
    };
  },
  computed: {
    ...mapGetters({
      searchParams: "SearchParams/getSearchParams",
    }),
  },
  methods: {
    ...mapActions({
      setSearchParams: "SearchParams/setSearchParams",
    }),
    initSearchParams() {
      this.setSearchParams({ ...this.initialSearchParams });
    },
    handleFetchItems(isSearchCleared = false) {
      if (isSearchCleared) this.initSearchParams();

      this.table.page = 1;
      this.fetchItems(isSearchCleared);
    },
    handleClickedRow({ user, count, category }) {
      const { id: userId } = JSON.parse(user);
      user = this.getUserFullname(user);
      this.$emit("viewItem", { id: userId, user, count, category });
    },
    fetchItems(isSearchCleared = false) {
      this.loading.table = true;
      this.table.isSearched = !this.isEqualObjects(
        this.initialSearchParams,
        this.searchParams
      );

      window.API.fetchReportedUsersByCount({
        limit: this.table.itemsPerPage,
        offset: (this.table.page - 1) * this.table.itemsPerPage,
        ...this.searchParams,
      })
        .then((response) => {
          this.table.items =
            this.table.isSearched || isSearchCleared
              ? response
              : [...this.table.items, ...response];
          this.table.totalCount = this.table.items.length;
        })
        .catch(() => {
          this.showFetchRequestErrorMessage();
        })
        .finally(() => {
          this.loading.table = false;
        });
    },
    getUserFullname(user) {
      if (!user) return "";

      const { firstName, lastName } = JSON.parse(user);
      return `${firstName} ${lastName}`;
    },
  },
  created() {
    this.$eventBus.$on("fetchReportedUsersByCount", this.handleFetchItems);
  },
  mounted() {
    this.initSearchParams();
    this.fetchItems();
  },
  beforeDestroy() {
    this.$eventBus.$off("fetchReportedUsersByCount", this.handleFetchItems);
  },
};
</script>

<style lang="scss">
.d-page__content {
  .v-data-table {
    &__wrapper {
      table > tbody > tr {
        cursor: pointer;
      }
    }
  }
}
</style>
