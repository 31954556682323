import axios from "axios";

import { getRefreshTokenPromise } from "@/utils/token.js";

const axiosClient = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL + "/api",
  timeout: 10000,
});

axiosClient.interceptors.request.use((config) => {
  const token = localStorage.getItem(process.env.VUE_APP_ACCESS_TOKEN_KEY);
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

axiosClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      !window.refreshTokenInProgress &&
      error.request.status === 403 &&
      error.config.url !== "/users/refresh-sign-in"
    ) {
      const response = await getRefreshTokenPromise();
      const config = error.config;

      if (!response) {
        this.$router.push("/login");
        throw error;
      }

      const { accessToken } = { ...response };
      config.headers.Authorization = `Bearer ${accessToken}`;

      return new Promise((resolve, reject) => {
        axios
          .request(config)
          .then((response) => resolve(response))
          .catch((e) => reject(e));
      });
    } else {
      throw error;
    }
  }
);

export default axiosClient;
