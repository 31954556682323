import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import store from "@/store";

import authorizationUtils from "@/utils/authorization.js";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const isPublic = to.matched.some((record) => record.meta.isOnlyPublic);
  const isRequireAuth = to.matched.some((record) => record.meta.requiresAuth);
  const role = to.meta.role;
  const isSigned = store.getters["Auth/isLoggedIn"];

  if (!isPublic && !isSigned) {
    return next({
      path: "/login",
      query: {
        redirect: to.path,
      },
    });
  }

  if (isRequireAuth && role && !authorizationUtils.isGranted(role)) {
    document.location = from.fullPath;
  }

  if (isSigned && !isRequireAuth) {
    return next("/");
  }

  next();
});

export default router;
