<template>
  <div>
    <v-data-table
      v-if="table.items.length > 0 || loading.table"
      :page="table.page"
      :headers="headers"
      :items="table.items"
      :items-per-page="table.itemsPerPage"
      :loading="loading.table"
      :server-items-length="table.totalCount"
      :footer-props="{
        'items-per-page-options': table.itemsPerPageOptions,
      }"
      class="d-table"
      :options.sync="table.options"
      @update:options="optionsUpdated"
    >
      <template v-slot:[`item.name`]="{ item }">
        <v-avatar color="primary" size="32">
          <d-auth-image
            v-if="item.image"
            :src="getImageSrc(item.image)"
          ></d-auth-image>
        </v-avatar>
        <span class="ml-2">{{ item.name }}</span>
      </template>
      <template v-slot:[`item.createdDate`]="{ item }">
        <span>{{ item.createdDate | moment("DD.MM.YYYY") }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div class="d-flex align-center justify-start">
          <v-btn class="mr-4" text small @click="viewItem(item)">
            <v-icon color="primary" size="18"> mdi-information-outline </v-icon>
            <span class="ml-2 gray--text text--darken-3">{{
              $t("users.list.table.actions.info")
            }}</span>
          </v-btn>
          <v-btn class="mx-4" text small @click="blockItem(item)">
            <v-icon color="error" size="18"> mdi-cancel </v-icon>
            <span class="ml-2 gray--text text--darken-3">{{
              $t("users.list.table.actions.block")
            }}</span>
          </v-btn>
          <v-menu bottom offset-y content-class="elevation-0">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ml-4"
                color="gray darken-2"
                icon
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon size="18"> mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>
          </v-menu>
        </div>
      </template>
    </v-data-table>
    <d-page-empty
      :text="$t('groups.list.empty-page')"
      :hasCreateButton="false"
      v-if="!table.isSearched && table.totalCount == 0 && !loading.table"
    >
    </d-page-empty>
    <d-no-result
      v-if="table.isSearched && table.totalCount == 0 && !loading.table"
    ></d-no-result>
  </div>
</template>

<script>
import tableMixins from "@/mixins/tableMixins";
import paginatedTableMixins from "@/mixins/paginatedTableMixins";
import { mapActions, mapGetters } from "vuex";

export default {
  mixins: [tableMixins, paginatedTableMixins],
  data(vm) {
    return {
      headers: [
        {
          text: vm.$t("groups.list.table.headers.name"),
          value: "name",
          sortable: false,
          align: "start",
          class: "subtitle-2 font-weight-bold",
        },
        {
          text: vm.$t("groups.list.table.headers.type"),
          value: "type",
          sortable: false,
          align: "start",
          class: "subtitle-2 font-weight-bold",
        },
        {
          text: vm.$t("groups.list.table.headers.createdDate"),
          value: "createdDate",
          sortable: false,
          align: "start",
          class: "subtitle-2 font-weight-bold",
        },
        {
          text: vm.$t("groups.list.table.headers.actions"),
          value: "actions",
          sortable: false,
          align: "start",
          class: "subtitle-2 font-weight-bold",
        },
      ],
      initialSearchParams: {},
    };
  },
  computed: {
    ...mapGetters({
      searchParams: "SearchParams/getSearchParams",
    }),
  },
  methods: {
    ...mapActions({
      setSearchParams: "SearchParams/setSearchParams",
    }),
    initSearchParams() {
      this.setSearchParams({ ...this.initialSearchParams });
    },
    handleFetchItems(isSearchCleared = false) {
      if (isSearchCleared) this.initSearchParams();

      this.table.options.page = 1;
      this.table.page = 1;
      this.fetchItems(isSearchCleared);
    },
    viewItem(item = null) {
      this.$emit("viewItem", item);
    },
    blockItem(item = null) {
      this.$emit("blockItem", item);
    },
    fetchItems() {
      this.loading.table = true;
      this.table.isSearched = !this.isEqualObjects(
        this.initialSearchParams,
        this.searchParams
      );

      window.API.fetchGroups({
        limit: this.table.itemsPerPage,
        offset: (this.table.page - 1) * this.table.itemsPerPage,
        ...this.searchParams,
      })
        .then((response) => {
          const totalCount = !response || !response.length ? 0 : 100_000; //response.totalCount

          this.table.items = response;
          this.table.totalCount = totalCount;

          if (
            totalCount <=
            (this.table.options.page - 1) * this.table.itemsPerPage
          ) {
            this.table.options.page = 1;
          }
        })
        .catch(() => {
          this.showFetchRequestErrorMessage();
        })
        .finally(() => {
          this.loading.table = false;
        });
    },
    getImageSrc(image) {
      if (!image) return "";

      return JSON.parse(image)?.url;
    },
  },
  created() {
    this.$eventBus.$on("fetchGroups", this.handleFetchItems);
  },
  mounted() {
    this.initSearchParams();
    this.fetchItems();
  },
  beforeDestroy() {
    this.$eventBus.$off("fetchGroups", this.handleFetchItems);
  },
};
</script>
