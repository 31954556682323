<template>
  <v-text-field
    class="user-search-input"
    :label="$t('groups.search.form.search-text')"
    v-model.trim="searchParams.criteria"
    hide-details="auto"
    filled
    rounded
    type="text"
    append-icon="mdi-magnify"
    clearable
    dense
    @click:clear="handleClear"
    @click:append="handleSearch"
    @keydown.enter="handleSearch"
  ></v-text-field>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      oldSearchText: null,
    };
  },
  watch: {
    searchParams: {
      handler() {
        this.setSearchParams(this.searchParams);
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      searchParams: "SearchParams/getSearchParams",
    }),
  },
  methods: {
    ...mapActions({
      setSearchParams: "SearchParams/setSearchParams",
    }),
    handleClear() {
      this.oldSearchText = null;
      this.$emit("clear");
    },
    handleSearch() {
      if (this.oldSearchText != this.searchParams.criteria) {
        this.oldSearchText = this.searchParams.criteria;

        this.oldSearchText && this.$emit("search");
        !this.oldSearchText && this.$emit("clear");
      }
    },
  },
};
</script>

<style lang="scss">
.user-search-input.v-input {
  .v-input__control {
    .v-input__slot {
      min-height: auto !important;
      background-color: #f5f8fa !important;

      .v-text-field__slot > .v-label,
      .v-icon {
        color: #8686a073;
      }
    }
  }
}
</style>
