const AuthorizationUtils = {
  isGranted(askedRole) {
    try {
      if (!askedRole) return false;

      if (askedRole === "*") return true;

      const role = localStorage.getItem(process.env.VUE_APP_USER_ROLE_KEY);

      if (!role) return false;

      const isOrCombination = askedRole.includes("|");

      return isOrCombination
        ? askedRole.split("|").includes(role)
        : askedRole === role;
    } catch (error) {
      return false;
    }
  },
};

export default AuthorizationUtils;
