<template>
  <v-card class="feedback-item rounded-lg" elevation="3">
    <v-card-title>
      <Title
        :item="item"
        :isSubmutting="loading.button"
        :approveItem="approveItem"
        :declineItem="declineItem"
      />
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <Content
        :description="item.description"
        :attachments="JSON.parse(item.attachments)"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import Title from "./Title.vue";
import Content from "./Content.vue";

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: {
        button: false,
      },
    };
  },
  components: {
    Title,
    Content,
  },
  methods: {
    approveItem() {
      this.loading.button = true;

      window.API.approveFeedback(this.item.id)
        .then(() => {
          this.$eventBus.$emit("notification", {
            type: "success",
            message: this.$t("feedbacks.list.item.actions.approve.success"),
          });
          this.$emit("approveOrDeclineItem");
        })
        .catch(() => {
          this.showFetchRequestErrorMessage();
        })
        .finally(() => {
          this.loading.button = false;
        });
    },
    declineItem() {
      this.loading.button = true;

      window.API.declineFeedback(this.item.id)
        .then(() => {
          this.$eventBus.$emit("notification", {
            type: "success",
            message: this.$t("feedbacks.list.item.actions.decline.success"),
          });
          this.$emit("approveOrDeclineItem");
        })
        .catch(() => {
          this.showFetchRequestErrorMessage();
        })
        .finally(() => {
          this.loading.button = false;
        });
    },
  },
};
</script>
