<template>
  <div>
    <d-loading v-if="loading.table"></d-loading>
    <v-data-iterator
      v-else-if="table.items.length > 0"
      :page="table.page"
      :items="table.items"
      :items-per-page="table.itemsPerPage"
      :server-items-length="table.totalCount"
      :footer-props="{
        'items-per-page-options': table.itemsPerPageOptions,
      }"
      hide-default-footer
    >
      <template v-slot:header>
        <v-row class="mx-2 mt-2 mb-10 border-bottom-special justify-start">
          <v-col class="subtitle-2 font-weight-bold mb-2" cols="3">{{
            $t("feedbacks.list.table.headers.user")
          }}</v-col>
          <v-col class="subtitle-2 font-weight-bold mb-2" cols="3">{{
            $t("feedbacks.list.table.headers.category")
          }}</v-col>
          <v-col class="subtitle-2 font-weight-bold mb-2" cols="3">{{
            $t("feedbacks.list.table.headers.publishDate")
          }}</v-col>
        </v-row>
      </template>

      <template v-slot:default="props">
        <v-row>
          <v-col
            class="mb-4"
            v-for="(item, index) in props.items"
            :key="index"
            cols="12"
            sm="12"
            md="6"
          >
            <FeedbackItem
              @approveOrDeclineItem="handleApproveOrDeclineItem"
              :item="item"
            />
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
    <d-page-empty
      :text="$t('feedbacks.list.empty-page')"
      :hasCreateButton="false"
      v-if="!table.isSearched && table.totalCount == 0 && !loading.table"
    >
    </d-page-empty>
    <d-no-result
      v-if="table.isSearched && table.totalCount == 0 && !loading.table"
    ></d-no-result>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import tableMixins from "@/mixins/tableMixins";
import scrolledTableMixins from "@/mixins/scrolledTableMixins";
import FeedbackItem from "./FeedbackItem/index.vue";

export default {
  mixins: [tableMixins, scrolledTableMixins],
  components: {
    FeedbackItem,
  },
  data() {
    return {
      initialSearchParams: {},
    };
  },
  computed: {
    ...mapGetters({
      searchParams: "SearchParams/getSearchParams",
    }),
  },
  methods: {
    ...mapActions({
      setSearchParams: "SearchParams/setSearchParams",
    }),
    initSearchParams() {
      this.setSearchParams({ ...this.initialSearchParams });
    },
    handleFetchItems(isSearchCleared = false) {
      if (isSearchCleared) this.initSearchParams();

      this.table.page = 1;
      this.fetchItems(isSearchCleared);
    },
    handleApproveOrDeclineItem() {
      this.$emit("approveOrDeclineItem");
    },
    fetchItems(isSearchCleared = false) {
      this.loading.table = true;
      this.table.isSearched = !this.isEqualObjects(
        this.initialSearchParams,
        this.searchParams
      );

      window.API.fetchFeedbacks({
        limit: this.table.itemsPerPage,
        offset: (this.table.page - 1) * this.table.itemsPerPage,
        ...this.searchParams,
      })
        .then((response) => {
          this.table.items =
            this.table.isSearched || isSearchCleared
              ? response
              : [...this.table.items, ...response];
          this.table.totalCount = this.table.items.length;
        })
        .catch(() => {
          this.showFetchRequestErrorMessage();
        })
        .finally(() => {
          this.loading.table = false;
        });
    },
  },
  created() {
    this.$eventBus.$on("fetchFeedbacks", this.handleFetchItems);
  },
  mounted() {
    this.initSearchParams();
    this.fetchItems();
  },
  beforeDestroy() {
    this.$eventBus.$off("fetchFeedbacks", this.handleFetchItems);
  },
};
</script>
