<template>
  <div>
    <h1 class="text-h5 text-center font-weight-medium mb-8">
      {{ $t("login.page-heading") }}
    </h1>

    <v-form
      ref="form"
      v-model="isValidForm"
      lazy-validation
      @submit.prevent="handleFormSubmit"
    >
      <v-row>
        <v-col cols="12" class="mb-8">
          <v-text-field
            :label="$t('login.form.username')"
            hide-details="auto"
            type="text"
            :rules="rules.username"
            v-model.trim="form.username"
            rounded
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="mb-6">
          <v-text-field
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            :label="$t('login.form.password')"
            hide-details="auto"
            @click:append="showPassword = !showPassword"
            :rules="rules.password"
            v-model.trim="form.password"
            rounded
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="6" class="mb-6">
          <v-checkbox
            small
            class="remember-me subtitle-1"
            :label="$t('login.form.remember-me')"
            v-model="form.rememberMe"
            depressed
            block
            :disable="loading"
            hide-details
          >
          </v-checkbox>
        </v-col>
        <v-col cols="6" class="mb-6" align="end">
          <span class="forgot-password subtitle-1">
            {{ $t("login.form.forgot-password") }}
          </span>
        </v-col>
        <v-col cols="12">
          <v-btn
            type="submit"
            color="primary"
            class="text-none body-2 font-weight-medium"
            depressed
            block
            rounded
            :loading="loading"
          >
            {{ $t("login.button.submit") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      showPassword: false,
      isValidForm: true,
      loading: false,
      rules: {
        username: [(v) => !!v || this.$t("login.rule.username")],
        password: [(v) => !!v || this.$t("login.rule.password")],
      },
      form: {
        username: null,
        password: null,
        rememberMe: false,
      },
    };
  },
  methods: {
    ...mapActions({
      setAccessToken: "Auth/setAccessToken",
      setRefreshToken: "Auth/setRefreshToken",
      setUserRole: "Auth/setUserRole",
      setLoggedInUser: "Auth/setLoggedInUser",
    }),
    handleFormSubmit() {
      const isFormValid = this.$refs.form.validate();
      if (isFormValid) {
        this.loading = true;

        window.API.logIn(this.form.username, this.form.password)
          .then(async (response) => {
            let { accessToken, refreshToken, user } = response;

            this.setCookies();
            this.setAccessToken(accessToken);
            this.setRefreshToken(refreshToken);
            this.setUserRole(user.role);
            this.setLoggedInUser(user);

            const { query } = this.$router.currentRoute;
            this.$router.push(query.redirect ? query.redirect : "/");
          })
          .catch(() => {
            this.form.password = null;

            this.$eventBus.$emit("notification", {
              type: "error",
              message: this.$t("login.alert.wrong-credentials"),
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    setCookies() {
      if (this.form.rememberMe) {
        Cookies.set("feedy-user-name", this.form.username, {
          expires: 365,
        });
        Cookies.set("feedy-user-password", this.form.password, {
          expires: 365,
        });
      } else {
        Cookies.remove("feedy-user-name");
        Cookies.remove("feedy-user-password");
      }
    },
  },
  created() {
    const email = Cookies.get("feedy-user-name");
    const password = Cookies.get("feedy-user-password");

    if (email && password) {
      this.form = {
        username: email,
        password: password,
        rememberMe: true,
      };
    }
  },
};
</script>
